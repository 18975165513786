import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDataProvider } from "react-admin";

export const UserAutocomplete = ({
  setUser,
  isOutDoorWorker,
  label,
  value,
}) => {
  const dataProvider = useDataProvider();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("users", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "lastName", order: "ASC" },
        filter: { isOutDoorWorker },
      })
      .then(({ data }) => {
        console.log(data);
        setUsersList(data);
      })
      .catch(() => {
        console.log("Ошибка получения списка Пользователей");
      });
  });

  return (
    <Autocomplete
      size="small"
      options={usersList}
      value={value ? usersList.find((user) => user.id === value) : null}
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      style={{ width: 300 }}
      onChange={(event, newValue) => setUser(newValue.id)}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
};
