import {
  TextField,
  List,
  DateField,
  Datagrid,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

export const BillList = (props) => (
  <List {...props} perPage={100} filters={billsFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="clusterId" reference="clusters">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="date" />
      <TextField source="temperature" />
      <TextField source="comment" />
      <ReferenceField source="userId" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField source="outDoorWorkerId" reference="users">
        <TextField source="lastName" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

const billsFilters = [
  <ReferenceInput
    label="Объект"
    source="clusterId"
    reference="clusters"
    alwaysOn
    perPage={500}
    key={"f1"}
    sort={{ field: "id", order: "ASC" }}
  >
    <AutocompleteInput
      size="small"
      label="Объект"
      optionText="code"
      filterToQuery={(search) => ({ code: search })}
    />
  </ReferenceInput>,
];
