import {
  DateInput,
  ReferenceInput,
  SelectInput,
  Edit,
  TextField,
  List,
  Datagrid,
  ReferenceField,
  NumberField,
  DateField,
  AutocompleteInput,
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";

export const MeasurementCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput source="billId" reference="bills">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput source="wellId" reference="wells">
        <SelectInput optionText="title" />
      </ReferenceInput>

      <DateInput source="date" />
      <TextInput source="heightTotal" />
      <TextInput source="heightAboveGround" />
      <TextInput source="depth" />
      <TextInput source="heightToWater" />
      <TextInput source="values" fullWidth />
      <TextInput source="comment" />
    </SimpleForm>
  </Create>
);

export const MeasurementEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput source="billId" reference="bills">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput source="wellId" reference="wells">
        <SelectInput optionText="title" />
      </ReferenceInput>

      <DateInput source="date" />
      <TextInput source="heightTotal" />
      <TextInput source="heightAboveGround" />
      <TextInput source="depth" />
      <TextInput source="heightToWater" />
      <TextInput source="values" fullWidth />
      <TextInput source="comment" />
    </SimpleForm>
  </Edit>
);

export const MeasurementList = (props) => {
  const measurementFilters = [
    <TextInput key={"f2"} label="Search" source="statusId" alwaysOn />,

    <ReferenceInput
      key={"f3"}
      label="Объект"
      source="billId"
      reference="bills"
      alwaysOn
    >
      <AutocompleteInput
        optionText="id"
        filterToQuery={(search) => ({ id: search })}
      />
    </ReferenceInput>,

    <ReferenceInput
      key={"f4"}
      label="Скважина"
      source="wellId"
      reference="wells"
      alwaysOn
    >
      <AutocompleteInput
        optionText="title"
        filterToQuery={(search) => ({ id: search })}
      />
    </ReferenceInput>,
  ];

  return (
    <List {...props} perPage={100} filters={measurementFilters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField source="date" />
        <NumberField source="depth" />
        <NumberField source="heightTotal" />
        <NumberField source="heightAboveGround" />
        <NumberField source="heightToWater" />
        <TextField source="values" />
        <TextField source="comment" />
        <ReferenceField source="billId" reference="bills">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="wellId" reference="wells">
          <TextField source="title" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
