import {
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { choicesStatuses } from "../../enums/wellstatuses";
import { useState } from "react";

const ChangeWellsStateButton = () => {
  const [clusterStatus, setClusterStatus] = useState(null);
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("wells");
  const [updateMany, { isLoading }] = useUpdateMany(
    "wells",
    { ids: selectedIds, data: { status: clusterStatus } },
    {
      onSuccess: () => {
        refresh();
        notify("Статус обновлен");
        unselectAll();
        setClusterStatus(null);
      },
      onError: () => notify("Ошибка обновления", { type: "warning" }),
    }
  );

  return (
    <Box>
      <TextField
        select
        size="small"
        label="Статус"
        placeholder="Выберите"
        sx={{ minWidth: 160 }}
        value={clusterStatus}
        onChange={(event) => setClusterStatus(event.target.value)}
      >
        {choicesStatuses.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      {clusterStatus && (
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          disabled={isLoading}
          visi
          onClick={() => updateMany()}
        >
          Обновить
        </Button>
      )}
    </Box>
  );
};

export default ChangeWellsStateButton;
