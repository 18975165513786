const createdAt = "Создан";
const updatedAt = "Обновлен";
const comment = "Комментарий";

const resources = {
  resources: {
    users: {
      users: "Сотрудники |||| Сотрудники",
      fields: {
        firstName: "Имя",
        lastName: "Фамилия",
        patronymic: "Отчество",
        isOutDoorWorker: "Полевик",
        isActive: "Действующий",
        positionId: "Должность",
      },
    },

    userspositions: {
      userspositions: "Должности |||| Должности",
      fields: {
        name: "Должность",
        isBoss: "Начальник отдела",
      },
    },

    positions: {
      positions: "Позиция |||| Позиции",
      fields: {
        number: "Технологический номер",
        name: "Название",
        clusterId: "Объект",
        typeId: "Тип",
        comment,
        createdAt,
        updatedAt,
      },
    },

    nodes: {
      nodes: "Узлы |||| Узлы",
      fields: {
        name: "Название",
        clusterId: "Объект",
        comment,
        createdAt,
        updatedAt,
      },
    },

    measurements: {
      measurements: "Изменеия |||| Замер",
      fields: {
        wellId: "Скважина",
        billId: "Ведомость",
        depth: "Фактическая глубина",
        heightTotal: "Высота с учетом наздемной части",
        heightAboveGround: "Высота кондуктора",
        heightToWater: "Вода на глубине",
        values: "Замеры температуры",
        comment,
        createdAt,
        updatedAt,
      },
    },

    wellstypes: {
      wellstypes: "Типы скважин |||| Типы скважин",
      fields: {
        name: "Тип скважины",
        depthProject: "Глубина проектная",
        comment,
        createdAt,
        updatedAt,
      },
    },

    clusters: {
      clusters: "Объект |||| Объекты",
      fields: {
        name: "Название",
        area: "Площадка",
        code: "Код",
        createdAt,
        updatedAt,
      },
    },

    wells: {
      wells: "Скважина |||| Скважины",
      fields: {
        depthProject: "глубина",
        title: "Название",
        nodeOrFragment: "Узел / Фрагмент",
        temperatureProject: "температура",
        useGroundPrinciple: "принцип использования грунта",
        sms: "СМС",
        clusterId: "Объект",
        typeId: "Тип скв.",
        nodeId: "Узел",
        positionId: "Поз.",
        status: "Статус",
        sort: "Сортировка",
      },
    },

    bills: {
      bills: "Ведомость |||| Ведомости",
      fields: {
        clusterId: "Объект",
        userId: "Составил",
        outDoorWorkerId: "Выполнил",
        date: "Дата",
        comment,
        heightLoad: "Высота груза",
        temperature: "Температура",
      },
    },
  },
};

export default resources;
