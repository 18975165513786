import {
  ReferenceInput,
  SelectField,
  SelectInput,
  Edit,
  TextField,
  List,
  Datagrid,
  ReferenceField,
  AutocompleteInput,
  Pagination,
  FormDataConsumer,
  Create,
  SimpleForm,
  TextInput,
  minValue,
  maxValue,
} from "react-admin";

import BullkButtons from "./bulkButtons";
import { choicesStatuses } from "../../enums/wellstatuses";

import { useForm } from "react-hook-form";

const ClusterLabel = "Объект";
const TypeLabel = "Тип скважины";
const PositionLabel = "Позиция";
const NodeLabel = "Узел";

const transform = (data) => ({
  ...data,
  typeId: data.typeId === "" ? null : data.typeId,
  nodeId: data.nodeId === "" ? null : data.nodeId,
  temperatureProject:
    data.temperatureProject === "" ? null : data.temperatureProject,
  depthProject: data.depthProject === "" ? null : data.depthProject,
  sms: data.sms === "" ? null : data.sms,
});

const isNumber =
  (message = "Глубина должна быть числом") =>
  (value) =>
    value && isNaN(Number(value)) ? message : undefined;

const validateDepthProject = [isNumber(), minValue(5), maxValue(25)];

export const WellCreate = (props) => {
  return (
    <Create {...props} redirect="list" transform={transform}>
      <SimpleForm redirect="show">
        <div>
          <ReferenceInput source="clusterId" perPage={500} reference="clusters">
            <SelectInput optionText="code" label={ClusterLabel} />
          </ReferenceInput>

          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="positionId"
                reference="positions"
                filter={{ clusterId: formData.clusterId }}
              >
                <SelectInput optionText="number" label={PositionLabel} />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="nodeId"
                reference="nodes"
                sort={{ field: "name", order: "ASC" }}
                filter={{ clusterId: formData.clusterId }}
              >
                <SelectInput optionText="name" label={NodeLabel} />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <ReferenceInput
            source="typeId"
            reference="wellstypes"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              optionText="name"
              label={TypeLabel}
              onChange={(event) => console.log(event)}
            />
          </ReferenceInput>

          <SelectInput source="status" choices={choicesStatuses} />
        </div>

        <TextInput source="title" />
        <TextInput source="depthProject" validate={validateDepthProject} />
        <TextInput source="temperatureProject" />
        <TextInput source="sms" />
        <TextInput source="useGroundPrinciple" />
        <TextInput source="sort" />
      </SimpleForm>
    </Create>
  );
};

export const WellEdit = (props) => {
  const { setValue } = useForm();

  const handleSetDepthProject = (event) => {
    console.log(event.target.value);

    setValue("depthProject", event.target.value);
  };

  return (
    <Edit {...props} transform={transform} redirect="list">
      <SimpleForm>
        <ReferenceInput source="clusterId" perPage={500} reference="clusters">
          <SelectInput optionText="code" label={ClusterLabel} />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="positionId"
              reference="positions"
              perPage={150}
              filter={{ clusterId: formData.clusterId }}
            >
              <SelectInput optionText="number" label={PositionLabel} />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="nodeId"
              reference="nodes"
              perPage={100}
              filter={{ clusterId: formData.clusterId }}
            >
              <SelectInput optionText="name" label={NodeLabel} />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <ReferenceInput
          source="typeId"
          reference="wellstypes"
          perPage={100}
          allowEmpty
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            optionText="name"
            label={TypeLabel}
            onChange={(event) => handleSetDepthProject(event)}
          />
        </ReferenceInput>

        <SelectInput source="status" choices={choicesStatuses} />
        <TextInput source="title" />
        <TextInput source="depthProject" />
        <TextInput source="temperatureProject" />
        <TextInput source="sms" />
        <TextInput source="useGroundPrinciple" />
        <TextInput source="sort" />
      </SimpleForm>
    </Edit>
  );
};

const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]} {...props} />
);

export const WellList = (props) => (
  <List
    {...props}
    perPage={50}
    filters={wellFilters}
    pagination={<PostPagination />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={<BullkButtons />}>
      <TextField source="id" />
      <ReferenceField source="clusterId" reference="clusters">
        <TextField source="code" />
      </ReferenceField>

      <ReferenceField source="typeId" reference="wellstypes" emptyText="">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="positionId" reference="positions">
        <TextField source="number" />
      </ReferenceField>

      <ReferenceField source="nodeId" reference="nodes">
        <TextField source="name" />
      </ReferenceField>

      <TextField source="title" />
      <SelectField source="status" choices={choicesStatuses} />
      <TextField source="depthProject" title="пр. глубина" />
      <TextField source="temperatureProject" title="пр. темп" />
      <TextField source="sms" />
      <TextField source="useGroundPrinciple" />
      <TextField source="sort" />
    </Datagrid>
  </List>
);

const PositionFilter = () => {
  return (
    <FormDataConsumer>
      {({ formData }) =>
        formData.clusterId && (
          <ReferenceInput
            label="Позиция"
            source="positionId"
            reference="positions"
            alwaysOn
            perPage={500}
            filter={{ clusterId: formData.clusterId }}
            sort={{ field: "number", order: "ASC" }}
          >
            <AutocompleteInput
              size="small"
              label="Позиция"
              optionText="number"
              filterToQuery={(search) => ({ number: search })}
            />
          </ReferenceInput>
        )
      }
    </FormDataConsumer>
  );
};

const wellFilters = [
  <ReferenceInput
    label="Объект"
    source="clusterId"
    reference="clusters"
    alwaysOn
    key={"f1"}
    perPage={500}
    sort={{ field: "id", order: "ASC" }}
  >
    <AutocompleteInput
      size="small"
      label="Объект"
      optionText="code"
      filterToQuery={(search) => ({ code: search })}
    />
  </ReferenceInput>,

  <PositionFilter key={"f2"} alwaysOn />,

  <TextInput label="Название" key={"f3"} source="title" alwaysOn resettable />,
];
