import React, { useState, useEffect } from "react";

import {
  useNotify,
  useRedirect,
  useRefresh,
  useDataProvider,
  useUpdate,
} from "react-admin";

import { ClusterAutocomplete } from "../Common/ClusterAutocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ruLocale from "date-fns/locale/ru";
import { format as formatDate, parseISO } from "date-fns";
import ClusterStatusSelect from "../../components/Common/ClusterStatusSelect";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import readLogger from "../../utils/readLogger";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import WaterIcon from "@mui/icons-material/Water";
import statuses from "../../enums/wellstatuses";
import Config from "../../config";
import { UserAutocomplete } from "../../components/Common/UserAutocomplete";
import { ChartDialog } from "./ChartDialog";

export const BillCreateOrEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [updateWellStatus, { error: errorUpdateWellStatus }] = useUpdate();

  const [openChartDialog, setOpenChartDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState(-1);
  const [openSelectWellStatus, setOpenSelectWellStatus] = useState(false);
  const [wellsList, setWellsList] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [clusterStatus, setClusterStatus] = useState(null);
  const [clusterId, setClusterId] = useState(null);
  const [billId, setBillId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [outDoorWorkerId, setOutDoorWorker] = useState(null);
  const [date, setDate] = useState("");
  const [comment, setComment] = useState("");
  const [temperature, setTemperature] = useState("");
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [loggerData, setLoggerData] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(20);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const id = Number(window.location.href.split("/").slice(-1));

    if (Number.isInteger(id)) {
      dataProvider
        .getOne("bills", { id })
        .then(({ data }) => {
          setClusterId(data.clusterId);
          setClusterStatus(data.clusterStatus);
          setBillId(id);
          setUserId(data.userId);
          setOutDoorWorker(data.outDoorWorkerId);
          setTemperature(data.temperature);
          setDate(parseISO(data.date));
          setComment(data.comment);
          if (data.loggerData) setLoggerData(data.loggerData);
          getWells(data.clusterId, data.clusterStatus, id);
        })
        .catch((error) => {
          console.log("Ошибка получения ведомости", error);
        });
    }
  });

  const showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();

    reader.onload = async (e) => {
      setLoggerData(readLogger(e.target.result));
    };

    reader.readAsText(e.target.files[0], "Windows-1251");
  };

  const handleCloseSelectWellStatus = () => setOpenSelectWellStatus(false);
  const handleCloseChartDialog = () => setOpenChartDialog(false);

  const handleChangePage = (event, value) => {
    setPageNumber(value);
  };

  const handleOpenSelectWellStatus = (row_index) => {
    setOpenSelectWellStatus(true);
    setCurrentRow(row_index);
  };

  const handleOpenChartDialog = (row_index) => {
    setOpenChartDialog(true);
    setCurrentRow(row_index);
  };

  const formatNumberInput = (value) => {
    if (typeof value === "string") {
      value = value.replace(",", ".");
      value = value.replace(/\.{2,}/, ".");
      value = value.replace(/[^\d\\.-]/, "");
    }

    return value;
  };

  const getMeasurementInitData = {
    statusId: null,
    hasWater: false,
    heightToWater: "",
    depth: "",
    numMetering: "",
    numBraid: "",
    date: "",
    time: "",
    values: [],
    heightTotal: "",
    heightAboveGround: "",
    comment: "",
  };

  const getLastMeasurementValue = (row, param) => {
    if (row.measurements && row.measurements.length > 0) {
      for (let i = row.measurements.length - 1; i >= 0; i--) {
        if (row.measurements[i][param]) return row.measurements[i][param];
      }
    }
    return null;
  };

  const selectClusterHandler = async (id) => {
    setClusterId(id);
    getWells(id, clusterStatus);
  };

  const getWells = async (clusterId, clusterStatus, billId) => {
    let response = await fetch(
      Config.server_url +
        "/wells/withMeasurements?clusterId=" +
        clusterId +
        "&clusterStatus=" +
        clusterStatus
    ); //  clusterStatus
    let data = await response.json();
    setWellsList(
      data.map((item) => {
        const currentBillMesurements = item.measurements.find(
          (m) => m.billId === billId
        );

        return currentBillMesurements
          ? {
              ...item,
              wellId: item.id,
              ...currentBillMesurements,
              values: JSON.parse(currentBillMesurements.values),
            }
          : { ...item, wellId: item.id, id: null, ...getMeasurementInitData };
      })
    );

    response = await fetch(Config.server_url + "/wellsstatuses");
    data = await response.json();
    setStatusesList(data);
  };

  const setClusterStatusHandler = (event) => setClusterStatus(event);

  const setCurrentWellStatus = (newStatus) => {
    updateWellStatus("wells", {
      id: wellsList[currentRow].id,
      data: { status: newStatus },
      previousData: { status: wellsList[currentRow].status },
    });

    const newList = wellsList.map((row, current) =>
      current !== currentRow ? { ...row } : { ...row, status: newStatus }
    );

    setWellsList(newList);
  };

  const selectMeasurement = (measurement, index) => {
    console.log(measurement);

    const newList = measurement
      ? wellsList.map((row, current) =>
          current !== index ? { ...row } : { ...row, ...measurement }
        )
      : wellsList.map((row, current) =>
          current !== index ? { ...row } : { ...row, ...getMeasurementInitData }
        );

    setWellsList(newList);
    const measurements = loggerData.filter((a) =>
      newList.find((b) => b.numMetering === a.numMetering)
    );
    setSelectedMeasurements(measurements);
  };

  const setWellStatusHandler = (value, index) => {
    const dateFormated = formatDate(date, "yyyy-MM-dd");

    console.log(value);
    const newList = wellsList.map((row, current) =>
      current !== index
        ? { ...row }
        : { ...row, statusId: value, date: dateFormated }
    );
    setWellsList(newList);
  };

  const setMeasurementDate = (value, index) => {
    value = formatDate(value, "yyyy-MM-dd");
    const newList = wellsList.map((row, current) =>
      current !== index ? { ...row } : { ...row, date: value }
    );
    setWellsList(newList);
  };

  //   const setMeasurementsComment = (value, index) =>
  //     (wellsList[index].comment = value);

  const IsBlockedRow = (index) => wellsList[index].statusId >= 7;
  //const IsGreenStatus = (index) => wellsList[index].statusId <= 3;

  const getStatusMeasurementRow = (index, depth, heigthToWater) => {
    let statusId = null;

    if (
      wellsList[index].depthProject -
        Math.floor(wellsList[index].depthProject) >
        0 &&
      depth <= wellsList[index].depthProject &&
      depth >= Math.floor(wellsList[index].depthProject)
    ) {
      statusId = 1;
    } else if (wellsList[index].depthProject <= depth) {
      statusId = 2;
    } else if (
      wellsList[index].depthProject > depth &&
      wellsList[index].depthProject * 0.7 < depth
    ) {
      statusId = 3;
    } else if (wellsList[index].depthProject * 0.7 >= depth) {
      statusId = 5;
    }

    if (wellsList[index].hasWater) {
      if (depth * 0.9 < heigthToWater) {
        statusId = 4;
      } else {
        statusId = 6;
      }
    }

    return statusId;
  };

  const setDepth = (value, index) => {
    value = formatNumberInput(value);

    const statusId = getStatusMeasurementRow(
      index,
      value,
      wellsList[index].heigthToWater
    );
    const newList = wellsList.map((row, current) =>
      current !== index ? { ...row } : { ...row, depth: value, statusId }
    );
    setWellsList(newList);
  };

  const setHasWater = (value, index) => {
    const newList = wellsList.map((row, current) =>
      current !== index ? { ...row } : { ...row, hasWater: !row.hasWater }
    );
    setWellsList(newList);
  };

  const setHeightToWater = (value, index) => {
    value = formatNumberInput(value);

    const statusId = getStatusMeasurementRow(
      index,
      wellsList[index].depth,
      value
    );
    const newList = wellsList.map((row, current) =>
      current !== index
        ? { ...row }
        : { ...row, heightToWater: value, statusId }
    );
    setWellsList(newList);
  };

  const setHeightTotal = (value, index) => {
    value = formatNumberInput(value);

    wellsList[index].heightTotal = value;
    setDepth(value - Math.abs(wellsList[index].heightAboveGround), index);
  };

  const setHeightAboveGround = (value, index) => {
    value = formatNumberInput(value);

    wellsList[index].heightAboveGround = value;
    setDepth(wellsList[index].heightTotal - Math.abs(value), index);
  };

  const copyOldValuesHandler = (index) => {
    const heightTotal = getLastMeasurementValue(
      wellsList[index],
      "heightTotal"
    );
    const heightAboveGround = getLastMeasurementValue(
      wellsList[index],
      "heightAboveGround"
    );
    const depth = getLastMeasurementValue(wellsList[index], "depth");

    if (heightTotal) wellsList[index].heightTotal = heightTotal;
    if (heightAboveGround)
      wellsList[index].heightAboveGround = heightAboveGround;
    if (depth) {
      setDepth(depth, index);
    } else {
      setDepth(heightTotal - Math.abs(heightAboveGround), index);
    }
  };

  const availabelMeasurements = loggerData.filter(
    (a) => !selectedMeasurements.find((b) => b.numMetering === a.numMetering)
  );

  const saveBillHandler = async () => {
    let data = {
      measurements: wellsList.filter(
        (well) => well.statusId && well.status === clusterStatus
      ),
      bill: {
        clusterId,
        clusterStatus,
        billId,
        userId,
        outDoorWorkerId,
        date: formatDate(date, "yyyy-MM-dd"),
        comment,
        temperature,
        loggerData,
      },
    };

    let response = await fetch(Config.server_url + "/bills/full", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      if (billId) notify(`Ведомость сохранена`);
      else notify(`Ведомость создана`);

      redirect("/bills");
      refresh();
    } else {
      notify(`Ошибка сохранения: `);
    }

    let result = await response.json();
    console.log(result);
  };

  const getOnlyNeededValues = (values, depth, heigthToWater) => {
    if (!values) return [];

    const depth_floor = Math.floor(depth) || 0;
    const heigthToWater_floor = Math.floor(heigthToWater) || 0;

    let level = depth_floor;

    if (heigthToWater_floor > 0) {
      level = heigthToWater_floor;
    }

    return values.slice(-level);
  };

  const changeMeasurementValue = (newValue, index) => {
    const shift = getOnlyNeededValues(
      wellsList[currentRow].values,
      wellsList[currentRow].depth,
      wellsList[currentRow].heightToWater
    ).length;
    const newValueIndex = wellsList[currentRow].values.length - shift + index;

    const newList = wellsList.map((row) => row);
    newList[currentRow].values[newValueIndex] = Number(newValue);
    setWellsList(newList);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", py: 2, alignItems: "center", gap: 5 }}>
        <ClusterStatusSelect
          disabled={clusterStatus}
          value={clusterStatus}
          setClusterStatus={(newValue) => setClusterStatusHandler(newValue)}
        />
        <ClusterAutocomplete
          disableOnSelect={true}
          value={clusterId}
          setCluster={selectClusterHandler}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          <DatePicker
            label="Дата"
            inputFormat="dd.MM.yyyy"
            value={date}
            onChange={(newValue) => setDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: 150 }} />
            )}
          />
        </LocalizationProvider>

        <UserAutocomplete
          setUser={setUserId}
          value={userId}
          isOutDoorWorker={0}
          label="Составил"
        />
        <UserAutocomplete
          setUser={setOutDoorWorker}
          value={outDoorWorkerId}
          isOutDoorWorker={1}
          label="Выполнил"
        />

        <Button variant="contained" color="primary" component="label">
          Выбрать файл{" "}
          <input type="file" hidden onChange={(e) => showFile(e)} />
        </Button>
        {/* <Button variant="contained" color="primary" onClick={() => ckeckTabel()}>Проверить таблицу</Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => saveBillHandler()}
        >
          Сохранить
        </Button>
      </Box>

      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <TextField
          label="Температура"
          type="text"
          size="small"
          sx={{ width: 150 }}
          error={temperature === ""}
          helperText={temperature === "" ? "" : " "}
          value={temperature}
          onChange={(e) => setTemperature(formatNumberInput(e.target.value))}
        />
        <TextField
          label="Коментарий"
          sx={{ flexGrow: 2 }}
          type="text"
          size="small"
          onBlur={(e) => setComment(e.target.value)}
        />
      </Box>

      <Stack spacing={2}>
        <Pagination
          siblingCount={20}
          boundaryCount={20}
          count={Math.ceil(wellsList.length / pageSize)}
          page={pageNumber}
          onChange={handleChangePage}
        />
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Скважина</TableCell>
              <TableCell>Номер измерения </TableCell>
              <TableCell>Номер косы</TableCell>
              <TableCell>Дата / Время</TableCell>
              <TableCell>Высота общая</TableCell>
              <TableCell>Высота над землей</TableCell>
              <TableCell>Глубина</TableCell>
              <TableCell>Вода</TableCell>
              <TableCell>Комментарий</TableCell>
              <TableCell>Измерения</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wellsList.map((row, index) => {
              if (
                index >= pageSize * (pageNumber - 1) &&
                index < pageSize * pageNumber
              )
                return (
                  <React.Fragment>
                    <TableRow key={row.id} className="bill-create-tabel-row">
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>
                        <div> {row.title}</div>

                        <Chip
                          label={statuses[row.status].name}
                          sx={{ mt: 1 }}
                          size="small"
                          variant="outlined"
                          onClick={() => handleOpenSelectWellStatus(index)}
                        />
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Autocomplete
                            sx={{ width: 125 }}
                            size="small"
                            autoSelect={true}
                            options={availabelMeasurements}
                            getOptionLabel={(option) => option.numMetering}
                            value={{ numMetering: row.numMetering }}
                            onChange={(event, newValue) =>
                              selectMeasurement(newValue, index)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                variant="outlined"
                              />
                            )}
                          />
                          <Box sx={{ mt: 1, fontSize: 12 }}>
                            h пр. = {row.depthProject} м
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell>{row.numBraid}</TableCell>

                      <TableCell>
                        <Box sx={{ minWidth: 75 }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ruLocale}
                          >
                            <DatePicker
                              label="Дата"
                              inputFormat="dd.MM.yyyy"
                              value={row.date}
                              onChange={(newValue) =>
                                setMeasurementDate(newValue, index)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  sx={{ width: 150 }}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          {row.time && (
                            <Box sx={{ fontSize: 12, mt: 1 }}>
                              Время: {row.time}
                            </Box>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 80,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: 13,
                              fontStyle: "italic",
                              color: "GrayText",
                            }}
                          >
                            {getLastMeasurementValue(row, "heightTotal")}
                            {row.measurements && (
                              <Button
                                disabled={IsBlockedRow(index)}
                                onClick={() => copyOldValuesHandler(index)}
                                size="small"
                                sx={{ minWidth: "initial" }}
                              >
                                ▼
                              </Button>
                            )}
                          </Box>

                          <TextField
                            disabled={IsBlockedRow(index)}
                            sx={{ width: 75 }}
                            size="small"
                            onChange={(event) =>
                              setHeightTotal(event.target.value, index)
                            }
                            value={row.heightTotal}
                          />
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 80,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: 13,
                              fontStyle: "italic",
                              color: "GrayText",
                            }}
                          >
                            {getLastMeasurementValue(row, "heightAboveGround")}
                          </Box>
                          <TextField
                            disabled={IsBlockedRow(index)}
                            sx={{ width: 70 }}
                            size="small"
                            onChange={(event) =>
                              setHeightAboveGround(event.target.value, index)
                            }
                            value={row.heightAboveGround}
                          />
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 80,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: 13,
                              fontStyle: "italic",
                              color: "GrayText",
                            }}
                          >
                            {" "}
                            {getLastMeasurementValue(row, "depth")}{" "}
                          </Box>
                          <TextField
                            disabled={IsBlockedRow(index)}
                            sx={{ width: 70 }}
                            size="small"
                            onChange={(event) =>
                              setDepth(event.target.value, index)
                            }
                            value={row.depth}
                          />
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 80,
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            title="Наличие воды"
                            icon={<WaterIcon />}
                            sx={{ py: 0 }}
                            value={row.heigthToWater}
                            disabled={IsBlockedRow(index)}
                            checkedIcon={
                              <WaterIcon sx={{ color: "#1976d2" }} />
                            }
                            onChange={(event) =>
                              setHasWater(event.target.value, index)
                            }
                          />
                          {row.hasWater && (
                            <TextField
                              disabled={IsBlockedRow(index)}
                              sx={{ width: 70 }}
                              size="small"
                              onChange={(event) =>
                                setHeightToWater(event.target.value, index)
                              }
                              value={row.heightToWater}
                            />
                          )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <Select
                            value={row.statusId}
                            renderValue={(selected) =>
                              statusesList.find((item) => item.id === selected)
                                ?.name
                            }
                            onChange={(event) =>
                              setWellStatusHandler(event.target.value, index)
                            }
                            sx={{
                              fontSize: 14,
                              p: 0,
                              backgroundColor: !row.statusId
                                ? "grey"
                                : row.statusId <= 4
                                ? "#bbffbbbf"
                                : "#f92c2c42",
                            }}
                          >
                            {statusesList.map((row, index) => (
                              <MenuItem
                                key={row.id}
                                value={row.id}
                                disabled={index + 1 <= 6}
                                sx={{
                                  backgroundColor:
                                    index + 1 < 5 ? "#bbffbbbf" : "#f92c2c42",
                                }}
                              >
                                {row.comment}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell>
                        {row.values.map((value, index) =>
                          row.values.length -
                            getOnlyNeededValues(
                              row.values,
                              row.depth,
                              row.heightToWater
                            ).length >
                          index ? (
                            <span
                              style={{
                                color: "grey",
                                fontStyle: "italic",
                                marginRight: "5px",
                              }}
                            >
                              {value}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: "bold", marginRight: "5px" }}
                            >
                              {value}
                            </span>
                          )
                        )}

                        <Chip
                          label="График"
                          size="small"
                          variant="outlined"
                          onClick={() => handleOpenChartDialog(index)}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openSelectWellStatus}
        onClose={handleCloseSelectWellStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Сменить статус скважины?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currentRow > -1 && (
              <ClusterStatusSelect
                value={wellsList[currentRow].status}
                setClusterStatus={(newValue) => setCurrentWellStatus(newValue)}
              />
            )}

            {errorUpdateWellStatus && "Ошибка обновления"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectWellStatus} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <ChartDialog
        openChartDialog={openChartDialog}
        handleCloseChartDialog={handleCloseChartDialog}
        changeMeasurementValue={changeMeasurementValue}
        well={wellsList[currentRow]}
        newDataRow={getOnlyNeededValues(
          wellsList[currentRow]?.values,
          wellsList[currentRow]?.depth,
          wellsList[currentRow]?.heightToWater
        )}
      />
    </React.Fragment>
  );
};
