import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDataProvider } from "react-admin";

export const ClusterAutocomplete = ({ setCluster, value, disableOnSelect }) => {
  const dataProvider = useDataProvider();
  const [clustersList, setClustersList] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("clusters", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "code", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        console.log(data);
        setClustersList(data);
      })
      .catch(() => {
        console.log("Ошибка получения списка кустов");
      });
  });

  return (
    <Autocomplete
      size="small"
      options={clustersList}
      getOptionLabel={(option) => option.code}
      style={{ width: 300 }}
      disabled={value && disableOnSelect}
      value={
        value ? clustersList.find((cluster) => cluster.id === value) : null
      }
      onChange={(event, newValue) => setCluster(newValue.id)}
      renderInput={(params) => (
        <TextField {...params} label="Объект" variant="outlined" />
      )}
    />
  );
};
