const WellIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="2em"
      height="2em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M3.62 8H5v7h2V8h4v2h2V8h4v7h2V8h1.61c.55 0 1-.44 1-1a.86.86 0 0 0-.05-.32L19 2H5L2.72 6.55A.998.998 0 0 0 3.62 8m2.62-4h11.52l1 2H5.24l1-2M2 16v2h2v4h16v-4h2v-2H2m16 4H6v-2h12v2m-4.07-9c.28 0 .5.22.5.5v.06l-.38 3c-.05.25-.24.44-.49.44h-3.12c-.25 0-.44-.19-.49-.44l-.38-3A.51.51 0 0 1 10 11h3.93Z"
      />
    </svg>
  );
};

export default WellIcon;
