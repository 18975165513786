import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  TextField,
  List,
  Datagrid,
  DateField,
} from "react-admin";

import { DateTimeCreateEditFields } from "../../components/Common/DateTime/DateTimeCreateEdit";

export const ClusterCreate = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm sx={{ maxWidth: 980 }} redirect="list">
        <TextInput source="area" fullWidth multiline rows={4} />
        <TextInput source="name" fullWidth multiline rows={2} />
        <TextInput source="code" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export const ClusterEdit = (props) => (
  <Edit {...props}>
    <SimpleForm sx={{ maxWidth: 980 }} redirect="list">
      <TextInput source="area" fullWidth multiline rows={4} />
      <TextInput source="name" fullWidth multiline rows={2} />
      <TextInput source="code" fullWidth />
      <DateTimeCreateEditFields />
    </SimpleForm>
  </Edit>
);

export const ClusterList = (props) => (
  <List {...props} filters={clusterFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="area" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

const clusterFilters = [
  <TextInput label="Площадка" source="area" alwaysOn resettable key={"f1"} />,
  <TextInput label="Название" source="name" alwaysOn resettable key={"f2"} />,
  <TextInput label="Код" source="code" alwaysOn resettable key={"f3"} />,
];
