import Box from "@mui/material/Box";
import { DateField } from "react-admin";

export const DateTimeCreateEditFields = () => (
  <Box sx={{ color: "grey !important", flexDirection: "column" }}>
    <div>
      Дата создания: <DateField source="createdAt" showTime />
    </div>
    <div>
      Дата редактирования: <DateField source="updatedAt" showTime />
    </div>
  </Box>
);
