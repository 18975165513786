import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectField,
  SelectInput,
} from "react-admin";

import { choicesTypesNotes } from "../../enums/notestypes";

export const NoteCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="value" fullWidth />
      <TextInput source="text" />
      <TextInput source="sort" />
      <SelectInput source="type" choices={choicesTypesNotes} />
    </SimpleForm>
  </Create>
);

export const NoteEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextInput source="value" fullWidth />
      <TextInput source="text" />
      <TextInput source="sort" />
      <SelectInput source="type" choices={choicesTypesNotes} />
    </SimpleForm>
  </Edit>
);

export const NotesList = (props) => (
  <List {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="value" />
      <TextField source="text" />
      <SelectField source="type" choices={choicesTypesNotes} />
      <TextField source="sort" />
    </Datagrid>
  </List>
);
