import { Card, CardContent, Box } from "@mui/material";
import { Title } from "react-admin";
import { ClusterAutocomplete } from "../Common/ClusterAutocomplete";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Config from "../../config";

export const Deleting = () => {
  const [cluster, setCluster] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const selectClusterHandler = async (clusterId) => {
    setCluster(clusterId);

    let response = await fetch(
      Config.server_url + "/report/clusterInfo?clusterId=" + clusterId
    );
    let data = await response.json();

    if (data.wells && data.wells.length > 0) {
      setColumns(Object.keys(data.wells[0]).map((key) => ({ field: key })));
      setRows(data.wells);
    }

    console.log(rows);
    console.log(columns);
  };

  return (
    <Card>
      <Title title="Удаление объектов" />
      {{ cluster }}
      <CardContent>
        <ClusterAutocomplete setCluster={selectClusterHandler} />
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      </CardContent>
    </Card>
  );
};
