const statuses = {
  under_construction: { name: "Строительство" },
  exploitation: { name: "Эксплуатация" },
};

const choicesStatuses = Object.keys(statuses).map((key) => ({
  id: key,
  name: statuses[key].name,
}));

export { choicesStatuses };

export default statuses;
