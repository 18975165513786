import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";

const options = {
  chart: {
    inverted: true,
    height: 500,
  },

  title: {
    text: "График температур",
  },

  subtitle: {
    text: "скважина №",
  },

  yAxis: {
    title: {
      text: "Температура",
    },
  },

  xAxis: {
    accessibility: {
      rangeDescription: "Range: 0 to 21",
    },
    title: {
      text: "Глубина",
    },

    tickInterval: 1,
    gridLineWidth: 1,
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },

  series: [
    {
      name: "Installation",
      data: [
        [1, 1],
        [1, 2],
        [1, 3],
      ],
    },

    {
      name: "Installation",
      data: [
        [15, 1],
        [15, 2],
        [15, 3],
      ],
    },

    //     {
    //     name: 'Installation',
    //     data: [-43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175, null,  null, null, null, null, null, null, null, null, null, null, null]
    // }, {
    //     name: 'Manufacturing',
    //     data: [-24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434, null,  null, null, null, null, null, null, null, null, null, null, null]
    // }, {
    //     name: 'Sales & Distribution',
    //     data: [-11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387, null,  null, null, null, null, null, null, null, null, null, null, null]
    // }, {
    //     name: 'Project Development',
    //     data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227, null,  null, null, null, null, null, null, null, null, null, null, null]
    // }, {
    //     name: 'Other',
    //     data: [-12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111, null,  null, null, null, null, null, null, null, null, null, null, null]
    // }
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

const mappingMeasurementsToSeries = (well, newDataRow) => {
  const series =
    well?.measurements.map((measurement) => {
      const values = JSON.parse(measurement.values);

      const data = values.map((m, i) => [i, m]);

      return { name: measurement?.date, data };
    }) || [];

  series.push({ name: "Новый ряд", data: newDataRow });

  const smsLine = [];
  for (let i = -30; i <= 30; i++) smsLine.push([well?.sms, i]);

  series.push({ name: "СМС", data: smsLine });

  return series;
};

export const ChartDialog = ({
  openChartDialog,
  handleCloseChartDialog,
  well,
  newDataRow,
  changeMeasurementValue,
}) => (
  <Dialog
    open={openChartDialog}
    maxWidth={800}
    fullWidth={true}
    onClose={handleCloseChartDialog}
    aria-labelledby="chart-dialog-title"
    aria-describedby="chart-dialog-description"
  >
    <DialogTitle id="chart-dialog-title">{"График температур"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="chart-dialog">
        <Table>
          <TableHead>
            <TableCell>Дата</TableCell>

            <TableCell>Замер</TableCell>
            <TableCell>Над землей</TableCell>
            <TableCell>Глубина</TableCell>

            {Array.from(Array(20).keys()).map((number) => (
              <TableCell key={number}>{number + 1}</TableCell>
            ))}
          </TableHead>

          {well?.measurements.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ maxWidth: 80, py: "0px", px: "4px" }}>
                {row.date}
              </TableCell>
              <TableCell>{row.heightTotal}</TableCell>
              <TableCell>{row.heightAboveGround}</TableCell>
              <TableCell>{row.depth}</TableCell>

              {JSON.parse(row.values).map((value, index) => (
                <TableCell key={index}>{value || "-"}</TableCell>
              ))}
            </TableRow>
          ))}

          <TableRow>
            <TableCell>Новый ряд</TableCell>
            <TableCell>{well?.heightTotal}</TableCell>
            <TableCell>{well?.heightAboveGround}</TableCell>
            <TableCell>{well?.depth}</TableCell>

            {newDataRow.map((value, index) => (
              <TableCell
                key={index}
                sx={{ maxWidth: 80, py: "0px", px: "4px" }}
              >
                <TextField
                  value={value}
                  sx={{
                    maxWidth: 50,
                    py: "2px",
                    px: "4px",
                    textAlign: "center",
                  }}
                  type="text"
                  size="small"
                  onChange={(e) =>
                    changeMeasurementValue(e.target.value, index)
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        </Table>

        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            series: mappingMeasurementsToSeries(well, newDataRow),
          }}
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseChartDialog} autoFocus>
        Закрыть
      </Button>
    </DialogActions>
  </Dialog>
);
