const types = {
  ts_name: { name: "Название скважины" },
  ts_type: { name: "Тип скважины" },
  ordinary: { name: "Включать всегда" },
};

const choicesTypesNotes = Object.keys(types).map((key) => ({
  id: key,
  name: types[key].name,
}));

export { choicesTypesNotes };

export default types;
