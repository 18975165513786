import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import statuses from "../../enums/wellstatuses";

const ClusterStatusSelect = ({ disabled, value, setClusterStatus }) => {
  return (
    <TextField
      size="small"
      select
      disabled={disabled}
      label="Статус"
      value={value == null ? "" : value}
      sx={{ mt: 2 }}
      onChange={(event) => setClusterStatus(event.target.value)}
      helperText="Укажите статус объекта"
    >
      {Object.keys(statuses).map((key) => (
        <MenuItem key={key} value={key}>
          {statuses[key].name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ClusterStatusSelect;
