import { DateTimeCreateEditFields } from '../../components/Common/DateTime/DateTimeCreateEdit';


import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  DateField
} from 'react-admin';

export const WellTypeCreate = props => (
  <Create {...props} redirect="list">
    <SimpleForm >        
      <TextInput source="name"/>
      <TextInput source="depthProject"/>
      <TextInput source="comment"/>      
    </SimpleForm>
  </Create>
);


export const WellTypeEdit = () => (
  <Edit>
      <SimpleForm redirect="list">        
          <TextInput source="name" />
          <TextInput source="depthProject"/>
          <TextInput source="comment" sx={{width: 400}}/>          
          <DateTimeCreateEditFields/>
      </SimpleForm>
  </Edit>
);


export const WellsTypesList = () => (
  <List>
      <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="depthProject"/>
          <TextField source="comment" />
          <DateField  source="createdAt" showTime/>
          <DateField  source="updatedAt" showTime/>
      </Datagrid>
  </List>
);