import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  Edit,
  required,
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
} from "react-admin";

import ImgFromBlob from "./../Common/Img/ImgFromBlob";

const validateRequired = required();

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="lastName" validate={validateRequired} />
      <TextInput source="firstName" validate={validateRequired} />
      <TextInput source="patronymic" />

      <ReferenceInput source="positionId" reference="userspositions">
        <SelectInput optionText="name" label={"Должность"} />
      </ReferenceInput>

      <BooleanInput source="isOutDoorWorker" />
      <BooleanInput source="isActive" defaultValue={true} />

      <ImageInput source="signatureImg" label="Подпись, скан" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="lastName" validate={validateRequired} />
      <TextInput source="firstName" validate={validateRequired} />
      <TextInput source="patronymic" />

      <ReferenceInput source="positionId" reference="userspositions">
        <SelectInput optionText="name" label={"Должность"} />
      </ReferenceInput>

      <BooleanInput source="isOutDoorWorker" parse={(v) => Boolean(v)} />
      <BooleanInput source="isActive" parse={(v) => Boolean(v)} />

      <ImageInput source="signatureImg" label="Подпись, скан" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImgFromBlob></ImgFromBlob>
    </SimpleForm>
  </Edit>
);

export const UserList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <TextField source="lastName" />
      <TextField source="firstName" />
      <TextField source="patronymic" />
      <ReferenceField source="positionId" reference="userspositions">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="isOutDoorWorker" />
      <BooleanField source="isActive" />
      <TextField source="email" />
    </Datagrid>
  </List>
);
