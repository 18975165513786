const getStartRowForSheet = (sheet) => {
  for (let i = 0; i < sheet.length - 1; i++) {
    if (
      sheet[i][0] === "Наименование объекта" ||
      sheet[i][0] === "Наименование позиции"
    ) {
      return i;
    }
  }

  return -1;
};

const isCorretDate = (date_str) => {
  return (
    /\d{1,2}-\d{1,2}-\d{4}/.test(date_str) ||
    /\d{1,2}\.\d{1,2}\.\d{4}/.test(date_str)
  );
};

const numberOrNull = (value) => {
  const numberValue = parseFloat(value.replace(",", "."));
  return !isNaN(numberValue) && typeof numberValue === "number"
    ? numberValue
    : null;
};

const readXLS = (arrayBuffer, clusterCode) => {
  const XLSX = require("xlsx");
  const workbook = XLSX.read(arrayBuffer, {
    type: "array",
    cellText: false,
    cellDates: true,
  });

  const countMeasurements = 20;

  const positionNameColumn = 3;
  const positionNumberColumn = 23;
  const positionStatusColumn = 28;

  const wellTemperatureProjectColumn = 35;

  const wellNumberColumn = 0;
  const wellTypeColumn = 1;
  const dateColumn = 2;
  const positionNodeColumn = 2;
  const temperatureColumn = 4;
  const depthColumn = 5;
  const depthProjectColumn = 6;
  const startValuesColumn = 7;
  const smsColumn = 28;
  const commentColumn = 29;
  const heightTotalColumn = 30;
  const heightAboveGroundColumn = 31;

  const result = {};
  result.sheets_D = {};
  result.sheets_E = {};

  result.positions = {};
  result.cluster = {};
  result.errors = [];
  result.clusterStatus = null;

  result.importSheets = workbook.SheetNames.filter((sheetname) =>
    sheetname.includes("Д_")
  );

  result.allSheets = workbook.SheetNames.map((sheetname) => {
    const isImport = result.importSheets.includes(sheetname);
    return { sheetname, isImport };
  });

  result.importSheets.forEach((sheetname) => {
    const sheet_to_json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname], {
      dateNF: "D-M-YYYY",
      raw: false,
      defval: "",
      header: 1,
    });
    result.sheets_D[sheetname] = sheet_to_json;
  });

  result.cluster.name = result.sheets_D[result.importSheets[0]][4][0];
  result.cluster.area = result.sheets_D[result.importSheets[0]][3][0];
  result.cluster.code = clusterCode;

  result.importSheets.forEach((sheetname) => {
    const sheetname_E = sheetname.replace("Д", "Е");

    const sheet_to_json = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheetname_E],
      {
        dateNF: "D-M-YYYY",
        raw: false,
        defval: "",
        header: 1,
      }
    );

    const well_to_temperature_project = {};

    sheet_to_json.forEach((row) => {
      if (row[wellNumberColumn] !== "") {
        well_to_temperature_project[row[wellNumberColumn]] = numberOrNull(
          row[wellTemperatureProjectColumn]
        );
      }
    });

    result.sheets_E[sheetname_E] = well_to_temperature_project;
  });

  const bills = {};
  const rows = [];
  let positionSort = 0;

  result.importSheets.forEach((sheetname) => {
    const positionSheet = sheetname.replace("Д_", "");
    const startRow = getStartRowForSheet(result.sheets_D[sheetname]);
    const includeNodes = result.sheets_D[sheetname][startRow + 2][
      positionNodeColumn
    ].includes("Узел")
      ? 1
      : 0;

    const positionName =
      result.sheets_D[sheetname][startRow][positionNameColumn + includeNodes];

    const positionNumber = ["", "_"].includes(
      result.sheets_D[sheetname][startRow][positionNumberColumn + includeNodes]
    )
      ? null
      : result.sheets_D[sheetname][startRow][
          positionNumberColumn + includeNodes
        ];

    if (!result.clusterStatus) {
      if (
        result.sheets_D[sheetname][startRow][
          positionStatusColumn + includeNodes
        ] === "Строительство"
      ) {
        result.clusterStatus = "under_construction";
      } else {
        result.clusterStatus = "exploitation";
      }
    }

    positionSort += 10;

    let wellSort = 0;

    result.sheets_D[sheetname].forEach((row) => {
      const sheetname_E = sheetname.replace("Д", "Е");

      wellSort += 10;

      if (isCorretDate(row[dateColumn + includeNodes])) {
        let values = row.slice(
          startValuesColumn + includeNodes,
          startValuesColumn + includeNodes + countMeasurements
        );
        values = values.map((value) => parseFloat(value.replace(",", ".")));
        values = JSON.stringify(values);

        const temperatureProject =
          result.sheets_E[sheetname_E][row[wellNumberColumn]];

        const newRow = {
          wellNumber: row[wellNumberColumn],
          wellType: row[wellTypeColumn],
          wellNode: includeNodes ? row[positionNodeColumn] : null,
          positionName,
          positionSort,
          wellSort,
          positionNumber,
          positionSheet,
          includeNodes,
          sheetname,
          temperatureProject,
          depthProject: numberOrNull(row[depthProjectColumn + includeNodes]),
          sms: numberOrNull(row[smsColumn + includeNodes]),

          values,
          date: row[dateColumn + includeNodes],
          temperature: numberOrNull(row[temperatureColumn + includeNodes]),
          comment: row[commentColumn + includeNodes],
          depth: numberOrNull(row[depthColumn + includeNodes]),
          heightTotal: numberOrNull(row[heightTotalColumn + includeNodes]),
          heightAboveGround: numberOrNull(
            row[heightAboveGroundColumn + includeNodes]
          ),
        };
        rows.push(newRow);
      } else {
        // result.errors.push('Некорректная дата: ' + row[dateColumn + includeNodes] + ', лист: ' + sheetname + ', строка: ' + index)
      }
    });
  });

  console.log(rows);

  let lastRow = null;

  rows.forEach((row, index) => {
    if (row.wellNumber !== "") {
      lastRow = {
        wellNumber: row.wellNumber,
        wellType: row.wellType,
        wellNode: row.wellNode,
        positionName: row.positionName,
        positionNumber: row.positionNumber,
        positionSheet: row.positionSheet,
        includeNodes: row.includeNodes,
        sheetname: row.sheetname,
        depthProject: row.depthProject,
        sms: row.sms,
        temperatureProject: row.temperatureProject,
        positionSort: row.positionSort,
        wellSort: row.wellSort,
      };
    } else {
      rows[index] = { ...row, ...lastRow };
    }
  });

  console.log(rows);

  rows.forEach((row) => {
    if (!bills[row.date]) {
      bills[row.date] = [];
    }

    bills[row.date] = [...bills[row.date], row];
  });

  console.log(bills);

  result.bills = bills;

  return result;
};

export default readXLS;
