import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import readXLS from "../../utils/readXLS";
import { ClusterAutocomplete } from "../Common/ClusterAutocomplete";
import ClusterStatusSelect from "../../components/Common/ClusterStatusSelect";

import Config from "../../config";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";

export const ImportDataOldFormat = () => {
  const [cluster, setCluster] = useState(null);
  const [clusterStatus, setClusterStatus] = useState(null);
  const [importData, setImportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const readXlsFileHandler = async (event) => {
    setLoading(true);
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (event) => {
      const xlsx = readXLS(event.target.result);
      setImportData(xlsx);
      setLoading(false);
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  const importDataHandler = async () => {
    console.log(importData);

    setLoading(true);

    let response = await fetch(Config.server_url + "/import", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({ cluster, importData, clusterStatus }),
    });

    let result = await response.json();

    setLoading(false);
    setSuccess(true);

    console.log(result);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
        }}
      >
        <ClusterAutocomplete
          helperText="Выберите объект"
          setCluster={setCluster}
        />
        <ClusterStatusSelect
          value={clusterStatus}
          setClusterStatus={setClusterStatus}
        />

        <Box>
          <input type="file" onChange={(event) => readXlsFileHandler(event)} />
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => importDataHandler()}
          disabled={importData.length === 0}
          sx={{ width: "fit-content", height: "fit-content" }}
        >
          {" "}
          Импортировать
        </Button>
      </Box>

      <Box sx={{ my: 2, width: "100%" }}>
        {loading && <LinearProgress />}
        {success && <Alert severity="success">Импортировано!</Alert>}
      </Box>

      <Box></Box>
    </React.Fragment>
  );
};
