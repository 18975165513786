import formatDate from "./formatDate.js";

const readLogger = (data) => {
  let lines = data.split("\n");
  lines = lines.slice(5);

  let dataTabel = [];

  for (let i = 0; i < lines.length; i += 3) {
    if (!/^n\d+/.test(lines[i])) {
      break;
    }

    const row1 = lines[i].trim().split("\t");
    const row2 = lines[i + 1].trim().split("\t");

    let values = row2.slice(3);
    values = values.map((value) => parseFloat(value.replace(",", ".")));

    const [d, m, y] = row1[1].split(".");
    let date = new Date(y, m - 1, d);
    date = formatDate(date);

    const getNumBraid = (row) => {
      const matches = row.match(/\[ (\d+) \]/);
      return matches && matches.length > 1 ? matches[1] : "0000";
    };

    try {
      dataTabel.push({
        numMetering: row1[0],
        numBraid: getNumBraid(row1[2]),
        date,
        time: row2[0],
        values,
        depth: "",
        heightTotal: "",
        heightAboveGround: "",
        comment: "",
      });
    } catch (e) {
      alert("Ошибка в строке " + (i + 5));
    }
  }

  return dataTabel;
};

export default readLogger;
