import { DateTimeCreateEditFields } from "../../components/Common/DateTime/DateTimeCreateEdit";

import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  DateField,
  AutocompleteInput,
} from "react-admin";

export const NodeCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <ReferenceInput source="clusterId" perPage={500} reference="clusters">
        <SelectInput optionText="code" label={"Объект"} />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="comment" />
    </SimpleForm>
  </Create>
);

export const NodeEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <ReferenceInput source="clusterId" perPage={500} reference="clusters">
        <SelectInput optionText="code" label={"Объект"} />
      </ReferenceInput>

      <TextInput source="name" />
      <TextInput source="comment" sx={{ width: 400 }} />
      <DateTimeCreateEditFields />
    </SimpleForm>
  </Edit>
);

export const NodesList = (props) => (
  <List {...props} filters={nodesFilters} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="clusterId" reference="clusters">
        <TextField source="code" />
      </ReferenceField>
      <TextField source="comment" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

const nodesFilters = [
  <ReferenceInput
    label="Объект"
    source="clusterId"
    reference="clusters"
    alwaysOn
    perPage={500}
    key={"f1"}
    sort={{ field: "id", order: "ASC" }}
  >
    <AutocompleteInput
      size="small"
      label="Объект"
      optionText="code"
      filterToQuery={(search) => ({ code: search })}
    />
  </ReferenceInput>,

  <TextInput key={"f2"} label="Узел" source="name" alwaysOn resettable />,
];
