import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

export const WellStatusCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="comment" />
      <TextInput source="printText" />
    </SimpleForm>
  </Create>
);

export const WellsstatusEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="comment" sx={{ width: 400 }} />
      <TextInput source="printText" />
    </SimpleForm>
  </Edit>
);

export const WellsstatusList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="comment" />
      <TextField source="printText" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
    </Datagrid>
  </List>
);
