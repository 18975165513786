import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import readXLS from "../../utils/readXLS.v2";
import ClusterStatusSelect from "../../components/Common/ClusterStatusSelect";

import Config from "../../config";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

export const ImportData = () => {
  const [clusterStatus, setClusterStatus] = useState(null);
  const [importData, setImportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resultMessages, setResultMessages] = useState([]);
  const [errors, setErrors] = useState([]);

  const readXlsFileHandler = async (event) => {
    setLoading(true);
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (event) => {
      const xlsx = readXLS(event.target.result, clusterCode);

      console.log(xlsx);

      setImportData(xlsx);
      setClusterStatus(xlsx.clusterStatus);
      setLoading(false);
    };

    const filename = event.target.files[0].name;
    const clusterCode = filename.replace("Приложение_Д,Е_", "").split("_")[0];

    reader.readAsArrayBuffer(event.target.files[0]);
  };

  const importDataHandler = async () => {
    console.log(importData);

    setLoading(true);

    let response = await fetch(Config.server_url + "/import/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({ importData, clusterStatus }),
    });

    let result = await response.json();

    setLoading(false);
    setErrors(result.errors);
    setResultMessages(result.messages);
    setSuccess(result.errors.length === 0);

    console.log(result);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
        }}
      >
        <ClusterStatusSelect
          value={clusterStatus}
          setClusterStatus={setClusterStatus}
          disabled={true}
        />

        <Box>
          <input type="file" onChange={(event) => readXlsFileHandler(event)} />
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => importDataHandler()}
          disabled={!importData}
          sx={{ width: "fit-content", height: "fit-content" }}
        >
          {" "}
          Импортировать
        </Button>
      </Box>

      <Box>
        {importData && (
          <Box>
            <TextField value={importData.cluster.area} fullWidth size="small" />
            <TextField value={importData.cluster.name} fullWidth size="small" />
            <TextField value={importData.cluster.code} fullWidth size="small" />
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", mt: 1, flexDirection: "row" }}>
        {importData &&
          importData.allSheets &&
          importData.allSheets
            .filter((sheet) => sheet.isImport)
            .map((sheet, index) => (
              <Box key={index} sx={{ display: "flex", mr: 3, width: "50px" }}>
                {sheet.sheetname}
              </Box>
            ))}
      </Box>

      <Box sx={{ display: "flex", mt: 1, flexDirection: "row" }}>
        {importData &&
          importData.allSheets &&
          importData.allSheets
            .filter((sheet) => !sheet.isImport)
            .map((sheet, index) => (
              <Box key={index} sx={{ display: "flex", mr: 3, width: "50px" }}>
                {sheet.sheetname}
              </Box>
            ))}
      </Box>

      <Box sx={{ display: "flex", mt: 1, flexDirection: "row" }}>
        {importData &&
          Object.keys(importData.positions).map((key) => (
            <Box sx={{ display: "flex", mr: 3 }} key={key}>
              {key}
            </Box>
          ))}
      </Box>

      <Box sx={{ my: 2, width: "100%" }}>
        {loading && <LinearProgress />}
        {success && <Alert severity="success">Импортировано!</Alert>}
      </Box>

      <Box sx={{ display: "flex", mt: 1, flexDirection: "column" }}>
        {!success && errors.lenght > 0 && (
          <Box>
            <Alert severity="error">Не импортировно!</Alert>
          </Box>
        )}

        {errors.map((error, i) => (
          <Box sx={{ color: "red" }} key={i}>
            {error.sqlMessage}
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", mt: 1, flexDirection: "column" }}>
        {resultMessages.map((message, i) => (
          <Box sx={{}} key={i}>
            {message}
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};
