import { useRecordContext } from "react-admin";

const ImgFromBlob = () => {
  const record = useRecordContext();

  if (!record["signatureImg"]) return;

  const data = record["signatureImg"].data;

  const udata = new Uint8Array(data);

  const blob = new Blob([udata], { type: "image/jpeg" });
  const objectURL = window.URL.createObjectURL(blob);

  console.log(objectURL);

  return (
    <div>
      <img src={objectURL} />
    </div>
  );
};

export default ImgFromBlob;
