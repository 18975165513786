const readXLS = (arrayBuffer) => {
  const xlsx = require("xlsx");
  const workbook = xlsx.read(arrayBuffer, {
    type: "array",
    cellText: false,
    cellDates: true,
  });
  const sheet_name_list = workbook.SheetNames;

  let tabelFromExel = xlsx.utils.sheet_to_json(
    workbook.Sheets[sheet_name_list[0]],
    {
      dateNF: "D-M-YYYY",
      raw: false,
      defval: "",
    }
  );

  const tabel = [];

  const isCorretDate = (date_str) => {
    return (
      /\d{1,2}-\d{1,2}-\d{4}/.test(date_str) ||
      /\d{1,2}\.\d{1,2}\.\d{4}/.test(date_str)
    );
  };

  tabelFromExel.forEach((tr) => {
    let row = Object.values(tr).slice(0, 51);

    if (isCorretDate(row[0])) {
      tabel.push(row);
    }
  });

  console.log(tabel);

  let currentWellNumber = "";

  tabel.forEach((row, index) => {
    if (row[2] !== "") {
      // let splited = row[2].split(' ')
      // currentWellNumber = splited[2]

      currentWellNumber = row[2];
    }

    tabel[index][2] = currentWellNumber;
  });

  let result = {};

  tabel.forEach((row) => {
    if (!result[row[0]]) {
      result[row[0]] = [];
    }

    result[row[0]] = [...result[row[0]], row];
  });

  return result;
};

export default readXLS;
