import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  Edit,
  List,
  Datagrid,
  TextField,
  BooleanField,
} from "react-admin";

export const UserPositionsCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <BooleanInput source="isBoss" />
    </SimpleForm>
  </Create>
);

export const UserPositionsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <BooleanInput source="isBoss" />
    </SimpleForm>
  </Edit>
);

export const UserPositionsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="isBoss" />
    </Datagrid>
  </List>
);
