import {
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { useState } from "react";
import { PositionAutocomplete } from "../../components/Common/PositionAutocomplete";

const ChangeWellsPositionButton = () => {
  const [positionId, setPosition] = useState(null);
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("wells");
  const [updateMany, { isLoading }] = useUpdateMany(
    "wells",
    { ids: selectedIds, data: { positionId } },
    {
      onSuccess: () => {
        refresh();
        notify("Статус обновлен");
        unselectAll();
        setPosition(null);
      },
      onError: () => notify("Ошибка обновления", { type: "warning" }),
    }
  );

  return (
    <Box>
      <PositionAutocomplete setPosition={setPosition} />

      {positionId && (
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 2 }}
          disabled={isLoading}
          visi
          onClick={() => updateMany()}
        >
          Обновить
        </Button>
      )}
    </Box>
  );
};

export default ChangeWellsPositionButton;
