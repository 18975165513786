import { BulkDeleteButton } from "react-admin";
import ChangeWellsStateButton from "./changeWellsStateButton";
import ChangeWellsPositionButton from "./changeWellsPositionButton";
import Box from "@mui/material/Box";

const BulkActionButtons = () => (
  <Box sx={{ dispay: "flex", flexDirection: "row", alignItems: "center" }}>
    <ChangeWellsStateButton />
    <ChangeWellsPositionButton />
    <BulkDeleteButton sx={{ ml: 2 }} />
  </Box>
);

export default BulkActionButtons;
