import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDataProvider, useListContext } from "react-admin";
import { useState, useEffect } from "react";

export const PositionAutocomplete = ({ setPosition }) => {
  const { filterValues } = useListContext();

  console.log(filterValues);

  const dataProvider = useDataProvider();
  const [positionsList, setPositionsList] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("positions", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: { clusterId: filterValues.clusterId },
      })
      .then(({ data }) => {
        console.log(data);
        setPositionsList(data);
      })
      .catch(() => {
        console.log("Ошибка получения списка позиций");
      });
  });

  return (
    <Autocomplete
      size="small"
      options={positionsList}
      getOptionLabel={(option) => option.number}
      style={{ width: 300 }}
      onChange={(event, newValue) => setPosition(newValue.id)}
      renderInput={(params) => (
        <TextField {...params} label="Объект" variant="outlined" />
      )}
    />
  );
};
