import { Admin, Resource, ShowGuesser, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";

import polyglotI18nProvider from "ra-i18n-polyglot";
import russianMessages from "ra-language-russian";
import { ClusterCreate, ClusterEdit, ClusterList } from "./components/Clusters";

import { BillCreateOrEdit } from "./components/Bills/BillCreateOrEdit";

import { WellCreate, WellEdit, WellList } from "./components/Wells";
import {
  WellsstatusEdit,
  WellsstatusList,
  WellStatusCreate,
} from "./components/WellsStatuses";
import {
  WellTypeCreate,
  WellTypeEdit,
  WellsTypesList,
} from "./components/WellsTypes";
import {
  MeasurementCreate,
  MeasurementEdit,
  MeasurementList,
} from "./components/Measurements";
import {
  PositionCreate,
  PositionList,
  PositionEdit,
} from "./components/Positions";
import { NoteCreate, NotesList, NoteEdit } from "./components/Notes";

import { NodeCreate, NodesList, NodeEdit } from "./components/Nodes";

import { Deleting } from "./components/Deleting";

import { ImportData } from "./components/Import.v2";
import { ImportDataOldFormat } from "./components/Import";

import { Applications } from "./components/Applications";
import Config from "./config";

import WellIcon from "./assets/icons/WellIcon.js";
import ClusterIcon from "./assets/icons/Cluster.js";
import BillIcon from "./assets/icons/BillIcon.js";
import MeasurementIcon from "./assets/icons/MeasurementIcon.js";
import ImportIcon from "./assets/icons/ImportIcon.js";
import ReportIcon from "./assets/icons/ReportIcon";
import PersonIcon from "@mui/icons-material/Person";

import { BillList } from "./components/Bills/BillsList";
import { UserCreate, UserEdit, UserList } from "./components/Users";
import {
  UserPositionsList,
  UserPositionsCreate,
  UserPositionsEdit,
} from "./components/UsersPositions";

import { MyLayout } from "./components/Common/MyLayout";
import "./assets/styles/style.css";
import resources from "./lang";

import crudProviderWithFileUpload from "./dataProviders/crudProviderWithFileUpload";

const messages = { ...russianMessages, ...resources };

const i18nProvider = polyglotI18nProvider(() => messages, "ru");

const dataProvider = crudProviderWithFileUpload(Config.server_url);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    layout={MyLayout}
  >
    <Resource
      name="clusters"
      options={{ label: "Объекты" }}
      list={ClusterList}
      create={ClusterCreate}
      edit={ClusterEdit}
      show={ShowGuesser}
      icon={ClusterIcon}
    />

    <Resource
      name="positions"
      options={{ label: "Позиции" }}
      list={PositionList}
      create={PositionCreate}
      edit={PositionEdit}
      show={ShowGuesser}
    />
    <Resource
      name="nodes"
      options={{ label: "Узлы" }}
      list={NodesList}
      create={NodeCreate}
      edit={NodeEdit}
      show={ShowGuesser}
    />

    <Resource
      name="wells"
      options={{ label: "Скважины" }}
      list={WellList}
      create={WellCreate}
      edit={WellEdit}
      show={ShowGuesser}
      icon={WellIcon}
    />
    <Resource
      name="wellstypes"
      options={{ label: "Типы скважин" }}
      list={WellsTypesList}
      create={WellTypeCreate}
      edit={WellTypeEdit}
      show={ShowGuesser}
    />
    <Resource
      name="wellsstatuses"
      options={{ label: "Статусы" }}
      list={WellsstatusList}
      create={WellStatusCreate}
      edit={WellsstatusEdit}
    />

    <Resource
      name="bills"
      options={{ label: "Ведомости" }}
      list={BillList}
      create={BillCreateOrEdit}
      edit={BillCreateOrEdit}
      show={ShowGuesser}
      icon={BillIcon}
    />

    <Resource
      name="measurements"
      options={{ label: "Измерения" }}
      list={MeasurementList}
      edit={MeasurementEdit}
      create={MeasurementCreate}
      icon={MeasurementIcon}
    />

    <Resource
      name="import"
      options={{ label: "Импорт" }}
      list={ImportData}
      icon={ImportIcon}
    />
    <Resource
      name="importold"
      options={{ label: "Импорт (старый)" }}
      list={ImportDataOldFormat}
      icon={ImportIcon}
    />

    <Resource
      name="applications"
      options={{ label: "Приложения" }}
      list={Applications}
      icon={ReportIcon}
    />

    <Resource
      name="users"
      options={{ label: "Пользователи" }}
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
      icon={PersonIcon}
    />
    <Resource
      name="userspositions"
      options={{ label: "Должности" }}
      list={UserPositionsList}
      create={UserPositionsCreate}
      edit={UserPositionsEdit}
      icon={PersonIcon}
    />

    <Resource
      name="notes"
      options={{ label: "Примечания" }}
      list={NotesList}
      create={NoteCreate}
      edit={NoteEdit}
      icon={PersonIcon}
    />

    <CustomRoutes>
      <Route path="/deleting" element={<Deleting />} />
    </CustomRoutes>
  </Admin>
);
export default App;
