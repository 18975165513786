
const server_url =
  process.env.NODE_ENV === "development"
    ? "http://[::1]:3000"
    : process.env.API_URL;

const Config = {
  server_url,
};

export default Config;
