import React, { useState } from "react";

import { ClusterAutocomplete } from "../Common/ClusterAutocomplete";
import Config from "../../config";

export const Applications = () => {
  const [cluster, setCluster] = useState(null);

  return (
    <React.Fragment>
      <ClusterAutocomplete setCluster={setCluster} />

      <a href={Config.server_url + "/report/download?clusterId=" + cluster}>
        скачать приложение
      </a>
    </React.Fragment>
  );
};
